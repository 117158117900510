.switch-to
    background-color: #fff
    padding: 24px
    box-shadow: 0 0 20px rgba(26, 24, 59, 0.2)
    border-radius: 4px
    h2
        font: 23px/23px "Red Hat Display Bold"
        margin-bottom: 24px
    .title
        font: 15px/18px "Red Hat Display Regular"
        color: rgba($project-global-color, 0.7)
        text-transform: lowercase
        margin-bottom: 8px
        display: block
        padding-left: 12px
        padding-top: 12px
        &::first-letter
            text-transform: uppercase
    .block
        background: transparent linear-gradient(180deg, rgba(248, 248, 250, 0.7) 0%, rgba(242, 242, 247, 0.7) 100%) 0% 0% no-repeat padding-box
        box-shadow: inset 0px 0px 1px rgba(25, 23, 56, 0.12), 0px 1px 2px rgba(25, 23, 56, 0.12)
        border-radius: 4px
        padding: 0
        &:nth-child(3)
            & ul
                display: flex
                flex-wrap: wrap
                justify-content: flex-start
                width: fit-content
                & li
                    max-width: 280px
        &:not(:nth-child(4))
            margin-bottom: 16px
    .ant-menu-item
        min-width: 248px
        background-color: transparent
        &:hover
            & a
                color: $add-button-text-color
        img
            margin-right: 12px
        a
            font: 15px/21px "Red Hat Display Regular"
    .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-item
        padding: 12px !important
        height: auto