.group_form
  & .ant-tabs-nav
    display: none
  & .selectWeekDays
    display: flex
    align-items: center
    justify-content: center
    margin: 20px 0
    & .selectDescription
      text-align: left
      font: 300 14px/22px "SF Pro Display Regular"
      letter-spacing: 0
      color: #898899
      margin: 0 15px