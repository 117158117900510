
@import "../../../assets/sass/components/_loaderAnimation"
.loading-page 
    position: fixed
    width: 100%
    height: 100vh
    background-color: #F2F2F7
    padding: 0
    margin: 0

    .header
        width: 100%
        height: 48px
        background-color: #FFFFFF
        box-shadow: 0px 0px 20px #1A183B33
        z-index: 2
        position: relative
        display: flex
        flex-wrap: nowrap

        & > div:nth-child(1)
            width: 200px
            align-items: center
            display: flex

            & > div
                width: 80%
                height: 60%
                margin: auto
                margin-left: 29px
                background-color: #F2F2F7
                @include background-gradient
                animation: shine-lines $animation-duration infinite linear

        & > div:nth-child(2)
            width: calc(100% - 200px)
            display: flex
            justify-content: space-between
            padding-left: 24px
            padding-right: 24px
            align-items: center

            & > div:nth-child(1)
                width: 196px
                height: 18px
                background-color: #F2F2F7
                @include background-gradient
                animation: shine-lines $animation-duration infinite linear

            & > div:nth-child(2)
                width: 30px
                height: 30px
                background-color: #F2F2F7
                @include background-gradient
                animation: shine-lines $animation-duration infinite linear

    .display-content
        width: 100%
        height: calc(100vh - 48px)
        padding: 0
        margin: 0
        display: flex
        flex-wrap: nowrap

        .left-bar
            max-width: 200px
            min-width: 200px
            width: 200px
            height: 100%
            box-shadow: 0px 0px 20px #1a183b33
            z-index: 1
            background-color: #fff
            padding-top: 31px

            & > div
                margin: 15px 20px
                display: flex

                & > div
                    background-color: #F2F2F7
                    @include background-gradient
                    animation: shine-lines $animation-duration infinite linear
                    height: 18px
                    width: 18px
                    margin-left: 19px

        .content
            width: calc(100% - 200px)
            padding: 24px
            height: 100%

            & > div
                width: 100%
                height: 100%
                background-color: #fff
                display: flex
                position: relative
                justify-content: center
                align-items: center

                & > div:nth-child(1)
                    position: absolute
                    top: 24px
                    left: 24px
                    width: 196px
                    height: 28px
                    background-color: #F2F2F7
                    @include background-gradient
                    animation: shine-lines $animation-duration infinite linear
                & > div:nth-child(2)
                    
                    .picture
                        width: 76px
                        height: 50px
                        background-color: #F2F2F7
                        @include background-gradient
                        animation: shine-lines $animation-duration infinite linear
                        margin: auto
                        margin-bottom: 27px
                    .cart-name
                        width: 72px
                        height: 15px
                        background-color: #F2F2F7
                        @include background-gradient
                        animation: shine-lines $animation-duration infinite linear
                        margin: auto
                        margin-bottom: 10px
                    .cart-lastname
                        width: 113px
                        height: 16px
                        background-color: #F2F2F7
                        @include background-gradient
                        animation: shine-lines $animation-duration infinite linear
                        margin: auto
                        margin-bottom: 25px
                    .contact
                        width: 155px
                        height: 40px
                        border-radius: 40px
                        background-color: #F2F2F7
                        @include background-gradient
                        animation: shine-lines $animation-duration infinite linear
                        display: flex
                        flex-wrap: nowrap
                        align-items: center
                        margin: auto
                        padding: 8px

                        & > div:nth-child(1)
                            width: 20px
                            height: 20px
                            border-radius: 50%
                            background-color: #fff
                            margin-right: auto
                        & > div:nth-child(2)
                            width: 98px
                            height: 15px
                            background-color: #fff
                            margin-right: 10px