@import "../../assets/sass/base/_colors"
.login-block
    &>div
        height: 100vh
        display: flex
        justify-content: center
        align-items: center

        .form-block.forgot
            width: 357px
            height: 302px

        .form-block
            width: 357px
            height: 425px

            h2
                color: #41479B
                text-transform: capitalize
                text-align: center
                font-weight: 900
                font-size: 31px
            p
                text-align: center
                font-size: 14px
                margin: 0px auto 24px auto
                width: 80%
            &.resent
                p
                    width: 100%

            & form>div
                &:last-child
                    margin-bottom: 0px

            &>div
                padding: 40px
                -webkit-box-shadow: 2px 0px 19px -4px #bdb9b9
                box-shadow: 2px 0px 19px -4px#bdb9b9
                border-radius: 4px
                &.icon-block
                    display: flex
                    background: unset
                    box-shadow: unset
                    padding: 36px
                    img
                        margin: auto
                
                .login-link
                    text-align: center
                    text-transform: capitalize

            label
                text-transform: capitalize

            .flex-betwen
                display: flex
                flex-direction: row
                justify-content: space-between

            button
                background-color: $project-global-color
                background: $project-global-color
                border-color: $project-global-color
                width: 100%
                height: 40px
                font-size: 16px
                &:hover
                    background-color: #fff
                    color:  $project-global-color
            &.login
                margin-bottom: calc((100vh - 425px) * 26 / 100)

        .forgot
            & form>div
                &:nth-child(1)
                    margin-bottom: 24px
                &:nth-child(2)
                    margin-bottom: 16px

            h2
                margin-bottom: 0px
    
    &.loading-silent
        display: none

*
    font-family: "Red Hat Display Regular"
.left-bg, .right-bg
    position: absolute
    width: 44.73vw
    height: 21.296vh
    overflow: hidden

    &::after, &::before
        content: ''
        width: 100%
        height: 50%
        display: block
        position: absolute

    &::after
        background-color: #6371DD
        height: 100%
        z-index: 1
    &::before
        background-color: #3C4482
        z-index: 2

.left-bg
    left: 0px
    bottom: 0px
    &::before, &::after
        bottom: 0px
        transform-origin: left top
    &::before
        transform: rotate(6.97deg)
    &::after
       transform: rotate(13.67deg)

.right-bg
    top: 0px
    right: 0px
    &::before, &::after
        top: 0px
        transform-origin: right bottom
    &::before
        transform: rotate(6.97deg)
    &::after
       transform: rotate(13.67deg)