.coupons-table-block
    table
        height: calc(100vh - 212px)
        overflow-y: auto
        overflow-x: hidden
        display: block
        thead
            th
                position: sticky
                top: 0
                z-index: 1
            tr:first-child
                th:last-child
                    min-width: 185px
                    max-width: 185px
        tbody
            tr
                td:nth-child(1)
                    min-width: 125px
                    max-width: 125px
                    text-overflow: ellipsis
        .ant-empty.ant-empty-normal
            width: calc(100vw - 791px)
            margin-left: var(--scrollpos)

    .loading-container
        .ant-spin
            height: 44px
            width: calc(100vw - 791px)
            margin-left: var(--scrollpos)
            align-items: center
            justify-content: center
            display: flex
            max-width: 968px
            margin: auto