.ant-upload.ant-upload-drag
    border: 0
    outline: 1px dashed rgba(#0050B3, 0.7)
    outline-offset: -20px
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover
    border-color: $logo-text-color
.ant-upload.ant-upload-drag p.ant-upload-text,
.ant-upload.ant-upload-drag p.ant-upload-hint
    text-align: center
    font: 300 12px/16px "SF Pro Display Regular"
    letter-spacing: 0
    color: #002766
    & .marked
        text-align: center
        text-decoration: underline
        font:  300 14px/16px "SF Pro Display Regular"
        letter-spacing: 0
        color: $logo-text-color