*,html,body
  margin: 0
  padding: 0
  box-sizing: border-box
  &:not(input, textarea)
    user-select: none
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
  &:hover,&:active,&:focus
    outline: 0

.content
  display: flex
  flex-direction: column
  height: 100%

.link
  cursor: pointer
  text-align: left
  text-decoration: underline
  font: 14px/19px "Red Hat Display Medium"
  letter-spacing: 0
  color: #191738
  opacity: 1

body
  overflow: hidden
.site-layout
  height: calc(100vh - 48px)
  overflow-y: scroll