.history-modal
    min-width: 500px
    &.ant-modal .ant-modal-body
        max-height: 500px
        overflow-y: auto
        padding: 0 6px 24px 0px
        margin-right: 18px

        .ant-empty .ant-empty-normal
            transform: translateX(24px)

    .history-row
        padding: 16px
        display: flex
        flex-wrap: nowrap
        justify-content: space-between
        align-items: flex-start

        &:nth-child(2n - 1)
            background: #F2F2F7 0% 0% no-repeat padding-box
            
        .history-name
            font: bold 14px/19px SF Pro Display Bold
            color: #191738
            width: 140px
            text-overflow: ellipsis
            overflow: hidden
        
        .history-body
            margin-left: 60px
            margin-right: 60px
            flex: 1
        * 
            white-space: nowrap
            .change-item
                align-items: center
                display: flex
                span
                    max-width: 100px
                    overflow: hidden
                    display: inline-block
                    text-overflow: ellipsis
                    margin-right: 5px
                    &:last-child
                        margin-left: 5px
        .time-diff
            span
                display: inline-block
                text-transform: lowercase
                &::first-letter
                    text-transform: uppercase

    .loading-container
        padding-top: 10px
            