.tableLoader
  .ant-table-tbody > tr > td:last-child
    width: fit-content !important
    min-width: fit-content !important
  .ant-table-tbody > tr > td:nth-last-child(2)
    width: 100%
    min-width: 170px
  & .ant-table .ant-table-container table .ant-table-tbody tr:not(.ant-table-measure-row) td
    padding: 0
    white-space: nowrap
  & .ant-skeleton-element,
  & .ant-skeleton-button
    height: auto
    line-height: normal
    width: auto
  & .ant-table-thead
    & .ant-skeleton:not(:first-child)
      padding: 0 16px !important
  & .ant-table-tbody
    & td
      & .ant-skeleton,
      & .ant-skeleton .ant-skeleton-content
        padding: 0 16px !important
      &:not(.noPadding)
        & .ant-skeleton,
        & .ant-skeleton .ant-skeleton-content
          min-width: 130px

    & .drag
      margin: 0 16px
      width: 10px
      height: 16px
      background: transparent url('@assets/img/icon/drag.svg') 0 0 no-repeat padding-box
      opacity: 1
    & .actions
      display: flex
      align-items: center
      justify-content: space-between
      & > .item .ant-skeleton-button.item
        width: 33px
        height: 22px
        border-radius: 4px
        opacity: 1
    & .icon

      & .ant-skeleton-content
        padding: 0 !important
        & .ant-skeleton-title
          margin: 0
          height: 33px
          width: 33px
  & .tableFooterLoader
    display: flex
    align-items: center
    justify-content: flex-end
    margin-top: 60px

    & .pageArrow .ant-skeleton-button.pageArrow
      width: 8px
      height: 18px
      border-radius: 4px
      margin-left: 24px
    & .pageItem .ant-skeleton-button.pageItem
      width: 15px
      height: 18px
      background: #F2F2F7 0 0 no-repeat padding-box
      border-radius: 4px
      margin-left: 24px
    & .pageItemBig .ant-skeleton-button.pageItemBig
      width: 32px
      height: 32px
      border-radius: 4px
      margin-left: 24px
    & .pageText .ant-skeleton-button.pageText
      width: 101px
      height: 32px
      border-radius: 4px
      margin-left: 24px
    & .pageDescription .ant-skeleton-button.pageDescription
      width: 26px
      height: 18px
      border-radius: 4px
      margin-left: 24px
    & .pageOf .ant-skeleton-button.pageOf
      width: 13px
      height: 18px
      background: #F2F2F7 0 0 no-repeat padding-box
      border-radius: 4px
      margin-left: 24px