.ant-modal
  & .ant-modal-content
    border-radius: 4px
    box-shadow: 0px 0px 20px $popup-shadow-color
    & .ant-modal-close
      color: $modal-icon-normal-color
      &:hover
        color: $modal-icon-hover-color
      &:active
        color: $modal-icon-active-color
      & .ant-modal-close-x
        width: auto
        height: auto
        line-height: normal
        padding: 24px
    & .ant-modal-header
      border-bottom: transparent
      padding: 24px
      & .ant-modal-title
        font: 23px/22px "Red Hat Display Bold"
        color: $popup-title-color