.clients
    .mainTable .ant-table-wrapper .ant-table-tbody > tr > td
        max-width: 500px !important
.products-tab
    .ant-form-item-control-input
        min-height: 36px
        input
            height: 36px
    .delete-icon
        cursor: pointer
        position: relative
        top: 4px
    .labels
        gap: 0px !important
        .ant-space-item
            width: calc((100% - 18px) / 2)
    .inputs
        padding: 16px
        padding-bottom: 0px
        &:nth-child(2n)
            background-color: #F8F8FA
        .ant-space-item
            width: calc((100% - 18px) / 2)
            &:nth-child(3)
                width: 20px
            &:nth-child(2)
                margin-left: 8px
    .add-button
        margin-top: 8px

        button
            display: flex
            flex-direction: row-reverse
            justify-content: center
            align-items: center
            gap: 3px
            width: 70px
            height: 36px
.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav
    display: none