.group-detals-main
    overflow-y: auto
    height: 100%

    .ant-upload.ant-upload-drag.avatar-uploader, .ant-upload.ant-upload-drag.preview-uploader
        width: 150px
        height: 150px

    .deposit-input
        display: flex
        flex-direction: column
        &.error
            input
                border-color: #ff4d4f !important

.coupon-form-block
    background: #F8F8FA 0% 0% no-repeat padding-box
    border: 1px solid #F2F2F7
    border-radius: 4px
    padding: 16px
    margin-top: 12px

    .ant-skeleton-element
        width: 100%
        margin-bottom: 8px
        height: 32px
        span
            height: 100%
        &.lable
            width: 20%
            height: 20px
                
    .button-skeleton
        width: 100%
        .ant-space-item
            width: 111px
            &:nth-child(1)
                margin-left: auto
            span
                width: 100%
                border-radius: 50px

    .coupon-form-header

        .coupon-form-title
            color: #41479B
            margin: 0 0 16px 0
            font: bold 16px/16px SF Pro Display Regular
    .ant-form-item-explain
        height: 0px !important
        min-height: 3px !important
    .MainModal--footer
        .ant-form-item-control-input-content
            text-align: right
            & button:nth-child(1)
                margin-right: 8px
                
    .ant-form-item 
        margin-bottom: 12px
        .ant-input-suffix
            font: 300 14px/22px SF Pro Display Regular
            color: #191738
            opacity: 0.5
    
    .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label
        padding: 0
    .MainModal--footer
        margin: 0
.ant-table-thead > tr > th:nth-child(8)
    text-align: center
