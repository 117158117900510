// antd Select customize
.ant-select-item
  font: 14px/21px "Red Hat Display Regular"
  color: $project-global-color
  &:not(.ant-select-item-option-disabled):hover
    color: $select-hover-text-color
    background-color: transparent
  &:not(.ant-select-item-option-disabled):focus,&:not(.ant-select-item-option-disabled):active
    background-color: $select-hover-background-color
.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  background-color: transparent
  color: $select-hover-text-color
  font: 14px/21px "Red Hat Display Regular"
.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  font-weight: normal