.data-grid
    display: flex
    flex-wrap: wrap
    align-content: flex-start
    overflow-y: auto
    max-height: calc(100vh - 210px)

.group-detals
    display: flex
    flex-wrap: nowrap
    flex-direction: row
    justify-content: space-between
    .coupons-table-block
        width: 100%
    .coupons-table-block
        width: 100%
        .ant-table-wrapper
            width: 100%
            .ant-spin-nested-loading
                width: 100%
                .ant-spin-container
                    width: 100%
                    .ant-table
                        width: 100%
                        .ant-table-container
                            width: 100%
                            overflow-x: auto
                            table
                                width: -webkit-fit-content
                                width: -moz-fit-content
                                width: fit-content
    .uploadFileContent--img
        padding: 10px
        height: auto
        position: absolute
    .mainTable .ant-table-thead > tr > th
        white-space: nowrap
    
    .ant-picker 
        width: 100%
    & > div:nth-child(1)
        max-width: 401px
        display: flex
        flex-direction: column
        padding: 24px 0
        margin-right: 24px

    & > div:nth-child(2)
        max-width: calc(100% - 426px)
        width: 74%

        .header-buttons
            display: flex
        
            & > button
                height: 36px
                &:nth-child(1)
                    margin-right: 8px
        .titleArea
            padding: 24px 5px
            max-width: calc(100% - 24px)
        .mainTable
            .deposit-input
                input
                    width: 140px
                    height: 36px
                    background: #FFFFFF 0% 0% no-repeat padding-box
                    border: 1px solid #D6D6DF
                    border-radius: 2px
            .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td
                padding: 7px 16px !important
                overflow: hidden

    .uploadFileContent
        background: #fff 0% 0% no-repeat padding-box
        border: 1px solid #f2f2f7
        height: 100%
        max-height: 152px

        &--infoBox
            position: absolute
            bottom: 0px
            padding: 0px 5px

            &--title
                display: none

        & .ant-upload-drag-container
            background: #fff 0% 0% no-repeat padding-box


    .group-item
        padding: 24px
        background: #F8F8FA 0% 0% no-repeat padding-box
        border: 1px solid #F2F2F7
        display: flex
        justify-content: space-between
        min-width: 401px

        .ant-skeleton-button-round
            width: 100%
            height: 36px

        .detals
            width: 187px
            display: flex
            flex-direction: column
            justify-content: space-between

            hr
                margin-top: auto

            h3   
                z-index: 1
                color: #191738
                font: 16px/19px "Red Hat Display Bold"
                margin-bottom: 4px
                max-width: 188px
                text-overflow: ellipsis
                overflow: hidden

            p
                z-index: 1
                color: #191738
                opacity: 0.7
                font: 14px/18px "Red Hat Display Regular"

            textarea
                z-index: 1
            
            .miniBtn
                width: 100%
        .ant-upload
            .uploadFileContent--infoBox
                background: #a3a2af 0% 0% no-repeat padding-box
                &:hover
                    background: #9a99aa 0% 0% no-repeat padding-box
            .delete
                display: none
            .edit
                display: flex
                flex-wrap: nowrap
                width: 100%
                align-items: center
                justify-content: space-between
                flex-direction: row-reverse
                color: #fff
                .text
                    display: inline-block
                    margin-left: 10px
                    margin-right: 10px
                    font: 14px/24px SF Pro Display Regular
                    opacity: 0.4
            .uploadFileContent--infoBox
                &:hover
                    .edit
                        img
                            color: #fff
                            fill: #fff
                        .text
                            opacity: 0.5


                    

    .enb-dis-block
        width: 100%
        display: flex
        justify-content: space-between
        align-items: center
        padding: 8px 0