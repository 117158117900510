@import "../../../assets/sass/components/_loaderAnimation"
.group-card
    width: 11.607%
    margin-right: 0.8%
    min-width: 182px

    @media only screen and (max-width: 1827px)
        width: 13.2475%
        margin-right: 0.96%

    @media only screen and (max-width: 1637px)
        width: 15.457%
        margin-right: 1%

    @media only screen and (max-width: 1433px)
        width: 18.604%
        margin-right: 1%

    @media only screen and (max-width: 1251px)
        width: 23.282%
        margin-right: 1.4%

    @media only screen and (max-width: 1067px)
        width: 31.258%
        margin-right: 1.5%
        
    .ant-skeleton-element, .ant-skeleton-element .ant-skeleton-image
        width: 100%
        height: 100%

    .ant-skeleton-image
        background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%)
        background-size: 600px
        animation: ant-skeleton-loading 1.4s ease infinite

    .img-block
        height: 182px
        width: 100%
        overflow: hidden
        
        img
            width: 100%
            margin: auto
    .group-text
        padding-top: 12px

        h3
            letter-spacing: 0px
            font: bold 16px/19px "Red Hat Display Regular"
            margin-bottom: 4px
            max-width: 190px
            text-overflow: ellipsis
            overflow: hidden
        
        p
            letter-spacing: 0px
            opacity: 0.7
            font: 14px/18px "Red Hat Display Regular"

        .ant-skeleton-element:nth-child(2)
            margin-bottom: 10px


.empty-groups-grid
    width: 100%
    height: calc(100vh - 211px)
    display: flex
    align-items: center
    justify-content: center
    & > div
        display: flex
        flex-direction: column
        & > span
            font: 16px/19px SF Pro Display Regular
            opacity: 0.3

    button
        margin: auto
        margin-top: 24px
        .anticon-plus-circle
            font: 19px/17px "Red Hat Display Medium"
            margin-right: 8px
        &:hover
            .anticon-plus-circle
                path
                    &:nth-child(1)
                        fill: #1890FF
                    &:nth-child(2)
                        fill: #1890FF
                    &:nth-child(3)
                        fill: #fff
        &:active
            .anticon-plus-circle
                path
                    &:nth-child(1)
                        fill: #096DD9
                    &:nth-child(2)
                        fill: #096DD9
                    &:nth-child(3)
                        fill: #fff