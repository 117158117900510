.ant-pagination
  color: $pagination-normal-color
  font: 15px/22px "Red Hat Display Regular"
  & .ant-pagination-item,
  & .ant-pagination-disabled .ant-pagination-item-link,
  & .ant-pagination-disabled:hover .ant-pagination-item-link,
  & .ant-pagination-disabled:focus .ant-pagination-item-link,
  & .ant-pagination-prev .ant-pagination-item-link,
  & .ant-pagination-next .ant-pagination-item-link
    border-color: transparent
  & .ant-pagination-item
    display: flex
    align-items: center
    justify-content: center
    height: 32px
    min-width: 32px
    border-radius: 6px
    &.ant-pagination-item-active
      border: 1px solid $pagination-border-color
      &:hover
        & a
          color: $pagination-normal-color
    & a
      color: $pagination-normal-color
      font: 15px/22px "Red Hat Display Regular"
    &:not(.ant-pagination-item-active):hover
      border-color: transparent
      & a
        color: $pagination-hover-color
    &:not(.ant-pagination-item-active):active
      background-color: $pagination-background-color
  & .ant-pagination-options
    width: 101px
    height: 32px
    margin-right: 24px
    & .ant-select-arrow
      transform: rotate(273deg)
    & .ant-select-open
      & .ant-select-arrow
        transform: rotate(180deg)
    &:hover
      & .ant-select-arrow
        color: rgba($pagination-hover-color, 0.5)
    &:active
      & .ant-select-arrow
        color: $pagination-hover-color
    & .ant-pagination-options-size-changer.ant-select.ant-select-single.ant-select-show-arrow
      & .ant-select-selection-item,
      & .ant-select-selection-placeholder
        display: flex
        align-items: center
        justify-content: center
        color: $pagination-normal-color
        font: 15px/22px "Red Hat Display Regular"
      & .ant-select-selector
        border-color: $pagination-option-border-color
      &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector
        box-shadow: none
      &:hover
        & .ant-select-selector
          border-color: $pagination-option-border-hover-color
      &:active
        &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-select-selector
          box-shadow: 0px 0px 8px $pagination-option-border-hover-color
      & .ant-select-dropdown
        & .rc-virtual-list
          & .rc-virtual-list-holder
            min-width: 116px
            text-align: center
            & .rc-virtual-list-holder-inner
              & .ant-select-item.ant-select-item-option
                font: 14px/21px "Red Hat Display Regular"
                color: $pagination-option-color

                &:hover
                  background-color: transparent
                  color: $pagination-hover-color
                  font: 14px/21px "Red Hat Display Regular"
                &:active
                  background-color: $pagination-option-hover-color
                &.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
                  background-color: $pagination-option-hover-color
                  font: 14px/21px "Red Hat Display Medium"
  & .ant-pagination-next, & .ant-pagination-prev
    & button
      border-radius: 6px
      color: $pagination-next-prev-color
    &:hover
      & button
        border-color: transparent
        color: $pagination-next-prev-hover-color
    &:active
      & button
        background-color: $pagination-background-color
  & .ant-pagination-jump-next.ant-pagination-jump-next-custom-icon,
  & .ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon
    & .anticon.anticon-double-right.ant-pagination-item-link-icon
      border-radius: 6px
      color:  $pagination-next-prev-hover-color
    &:hover
      & .anticon.anticon-double-right.ant-pagination-item-link-icon
        & .anticon.anticon-double-right.ant-pagination-item-link-icon
          border-color: transparent
          color: $pagination-next-prev-hover-color
    &:active
      background-color: $pagination-background-color
