.expired, .active, .waggered
    font: 14px/19px "Red Hat Display Regular"
    text-transform: capitalize
.expired
    color: #EA6331
.active
    color: #00CE7E
.wagered
    color: #5986FF
.pending
    color: #5E5C73
.lost
    color: #D90B0B
.wallets-filter
    .ant-select-selection-overflow-item
        // max-width: calc(100% / 3 - 1px)
    // .searchBar--row.show .searchBar--col
    //     width: calc(100% / 6 - 7px)
    .filterPart
        width: 220px
        button
            width: 100px
.wallets
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td
        padding: 14px 16px !important
        white-space: nowrap
    .wagering-progress
        min-width: 310px
    .ant-table-content
        overflow-x: auto