$base-color: #F4F4F4
$shine-color: rgba(229,229,229,0.8)
$animation-duration: 1s
$avatar-offset: 52 + 16

// this unfortunately uses set px widths for the background-gradient, I never got around to changing it to work with all widths :(
@mixin background-gradient
  background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px)
  background-size: 600px



@keyframes shine-lines
  0%
    background-position: -100px

  40%, 100%
    background-position: 300px

@keyframes shine-avatar
  0%
    background-position: -100px + $avatar-offset

  40%, 100%
    background-position: 300px + $avatar-offset


