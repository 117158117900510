@import "../base/_colors"
.mainBtn
  border: unset
  background: #FFFFFF
  opacity: 1
  text-align: left
  font: 15px/20px "Red Hat Display Medium"
  letter-spacing: 0
  color: $botton-default-color
  padding: 8px
  display: flex
  align-items: center
  justify-content: center
  transition: border-color ease-in-out 0.3s
  cursor: pointer
  line-height: 16px
  & .icon
    position: relative
    width: 20px
    height: 20px
    background: #fff 0 0 no-repeat padding-box
    border: 2px $botton-default-color solid
    border-radius: 50%
    margin-right: 8px

    &:before
      content: ""
      width: 12px
      height: 2px
      background: $botton-default-color
      position: absolute
      top: calc(50% - 1px)
      left: 2px

    &:after
      content: ""
      width: 2px
      height: 12px
      background: $botton-default-color
      position: absolute
      top: 2px
      left: calc(50% - 1px)

  &:hover
    color: $botton-default-color
    & .icon
      background: $botton-default-color
      border-color: $botton-default-color
      &:before, &:after
        background: #fff
  &:active
    color: $botton-focus-color
    & .icon
      background: $botton-focus-color
      border-color: $botton-focus-color
      &:before, &:after
        background: #fff

.miniBtn
  background: $botton-default-color 0 0%no-repeat padding-box
  border-radius: 62px
  text-align: center
  font:  14px/24px "SF Pro Display Regular"
  letter-spacing: 0
  color: #FFFFFF
  padding: 10px 38px
  cursor: pointer
  border: transparent
  line-height: 16px
  &.ant-btn,  &.ant-btn-primary[disabled]
    background: $botton-default-color 0 0%no-repeat padding-box
  &.default
    background: #F8F8FA 0 0 no-repeat padding-box
    border-radius: 52px
    font: 14px/24px "SF Pro Display Regular"
    letter-spacing: 0
    color: $project-global-color
    padding: 10px 32px
    text-align: center
    line-height: 16px
    &[disabled]:hover,
    &[disabled]:active
      color: $project-global-color
    &.border
      border: 1px solid #DCDCE4
    &:hover,
    &.ant-btn:hover
      background: #F8F8FA 0 0 no-repeat padding-box
      color: $botton-default-color
    &:active,
    &.ant-btn:active
      background: #F8F8FA 0 0 no-repeat padding-box
      color: $botton-focus-color

  &[disabled]
    cursor: unset
    opacity: 0.5
  & .icon
    width: 16px
    height: 16px
    margin: 0 8px 0 0
  &.small
    padding: 10px 24px
  &.bg_dark
    background-color: #191738
  &.ant-btn-primary[disabled]:hover,
  &.ant-btn-primary[disabled]:active
    background: $botton-default-color 0 0%no-repeat padding-box
  &:not(.btnRadio):not(.default):not(.miniBtn[disabled]):hover,
  &.ant-btn:not(.btnRadio):not(.default):not(.miniBtn[disabled]):hover
    background: $botton-focus-color !important
  &:not(.btnRadio):not(.default):not(.miniBtn[disabled]):active,
  &.ant-btn:not(.btnRadio):not(.default):not(.miniBtn[disabled]):active
    background: $logo-text-color !important
  &.btnRadio
    &:hover
      color: rgba($icon-focus-color, 0.7)
    &:focus
      color: $icon-focus-color
.durationsBtn
  position: relative
  width: 36px
  height: 32px
  background: #F8F8FA 0 0 no-repeat padding-box
  border: 1px solid #DCDCE4
  border-radius: 2px
  opacity: 1
  cursor: pointer
  line-height: 16px
  &:before
    content: ""
    width: 2px
    height: 13px
    background-color: #191738
    opacity: 0.5
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0
    margin: auto
  &:after
    content: ""
    width: 2px
    height: 13px
    background-color: #191738
    opacity: 0.5
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0
    margin: auto
    transform: rotate(90deg)
  &.remove
    &:before
      transform: rotate(45deg)
    &:after
      transform: rotate(135deg)
  &[disabled]
    cursor: not-allowed
    opacity: 0.5

.btn_1
  background: $project-text-color 0 0 no-repeat padding-box
  border-radius: 2px
  text-align: left
  font: 300 14px/22px "SF Pro Display Regular"
  letter-spacing: 0
  color: #FFFFFF
  display: flex
  align-items: center
  justify-content: center
  transition: border-color ease-in-out 0.3s
  cursor: pointer
  border: 1px solid $project-text-color
  padding: 5px 13px
  line-height: 16px
  & .icon
    position: relative
    width: 24px
    height: 24px
    margin: 0 0 0 8px

    &:before
      content: ""
      width: 12px
      height: 2px
      background: #FFFFFF
      position: absolute
      top: calc(50% - 1px)
      left: 6px

    &:after
      content: ""
      width: 2px
      height: 12px
      background: #FFFFFF
      position: absolute
      top: 6px
      left: calc(50% - 1px)
  &[disabled]
    opacity: 0.5
    cursor: not-allowed
.filterBtn
  position: relative
  background: #FFFFFF 0 0 no-repeat padding-box
  border: 1px solid transparent
  border-radius: 2px
  text-align: right
  font: 15px/24px "Red Hat Display Medium"
  letter-spacing: 0px
  color: $button-done-color
  padding: 8px 24px 8px 12px
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  & .icon
    width: 20px
    height: 20px
    background: url("@assets/img/icon/filter1.svg")  no-repeat
    background-size: cover
    margin-right: 8px
  &:before
    content: ""
    position: absolute
    right: 12px
    width: 7px
    height: 7px
    border-top: 1px solid $button-done-color
    border-right: 1px solid $button-done-color
    transform: rotate(45deg)
    transition: 0.3s ease-in-out
  & img
    width: 24px
    height: 24px
  &.show
    &:before
      transform: rotate(135deg)
  &:hover
    & .icon
      background-image: url("@assets/img/icon/filter.svg")
  &:active
    color: $botton-focus-color
    & .icon
      background-image: url("@assets/img/icon/filter2.svg")
    &:before
      border-top: 1px solid $botton-focus-color
      border-right: 1px solid $botton-focus-color


.btn_2
  padding: 4px 8px
  background: transparent
  text-align: center
  font: 14px/19px "Red Hat Display Regular"
  letter-spacing: 0px
  color: $project-text-color
  border: 1px solid transparent
  cursor: pointer
  transition: color background-color 0.3s , 0.3s ease-in-out
  &:hover
    color: $botton-default-color
  &:active
    background-color: $button-default-background-color
.csvBtn
  display: flex
  align-items: center
  justify-content: center
  padding: 4px 8px
  text-align: center
  font: 14px/19px "Red Hat Display Regular"
  letter-spacing: 0px
  color: $botton-border-color
  border: 1px solid transparent
  background-color: transparent
  cursor: pointer
  border-radius: 13px
  & .downloadIcon
    margin: 0 8px 0 0
    width: 16px
    height: 16px
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: center
    & .arrow
      display: block
      height: 12px
      width: 1px
      background-color: $botton-border-color
      position: relative
      margin-bottom: 2px
      &:before
        content: ""
        position: absolute
        width: 1px
        height: 6px
        bottom: 0
        right: 2px
        background-color: $botton-border-color
        transform: rotate(315deg)
      &:after
        content: ""
        position: absolute
        width: 1px
        height: 6px
        background-color: $botton-border-color
        bottom: 0
        left: 2px
        transform: rotate(45deg)
    & .underline
      width: 100%
      height: 1px
      background-color: $botton-border-color
      display: block
  &:hover
    color: $button-done-color
    & .downloadIcon
      & .arrow
        background-color: $button-done-color
        &:before
          background-color: $button-done-color
        &:after
          background-color: $button-done-color
      & .underline
        background-color: $button-done-color
  &:active
    background-color: $botton-default-bg-color
    background: $botton-default-bg-color
  & img
    width: 16px
    height: 16px
    margin: 0 8px 0 0
  &.downloading
    cursor: default
    background-color: transparent
    color: $project-global-color
    & .cancelArea
      display: flex
      align-items: center
      justify-content: center
      & .loading
        cursor: pointer
        margin-right: 8px
        width: 20px
        height: 20px
        border: 1px solid #ccc
        border-radius: 50%
        position: relative
        box-sizing: border-box
        & .icon
          position: relative
          height: 100%
          &:before
            position: absolute
            left: 3px
            top: 9px
            content: ""
            width: 12px
            height: 1px
            background-color:  $project-global-color
            transform: rotate(45deg)
          &:after
            position: absolute
            left: 3px
            top: 9px
            content: ""
            width: 12px
            height: 1px
            background-color:  $project-global-color
            transform: rotate(315deg)
        & .loader
          position: absolute
          top: -1px
          left: -1px
          width: 20px
          height: 20px
          border-width: 1px
          border-style: solid
          border-color: transparent $project-global-color  transparent transparent
          border-radius: 50%
          box-sizing: border-box
          animation-name: rotet
          animation-duration: 2s
          animation-iteration-count: infinite
      &.cancel
        cursor: pointer
        pointer-events: none
        opacity: 0.4
        & .loading
          & .loader
            border-color:  $project-global-color

@keyframes rotet
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)
