.deposit-groups
    .ant-skeleton-element
        width: 100%
    .titleArea--BtnGroup
        display: flex
        align-items: center
        .mainBtn
            min-width: 130px
            margin-left: 20px
            .anticon-plus-circle
                font: 19px/17px "Red Hat Display Medium"
                margin-right: 8px
            &:hover
                .anticon-plus-circle
                    path
                        &:nth-child(1)
                            fill: #1890FF
                        &:nth-child(2)
                            fill: #1890FF
                        &:nth-child(3)
                            fill: #fff
            &:active
                .anticon-plus-circle
                    path
                        &:nth-child(1)
                            fill: #096DD9
                        &:nth-child(2)
                            fill: #096DD9
                        &:nth-child(3)
                            fill: #fff
    .ant-select
        min-width: 250px
        min-height: 36px
        & > span[aria-live]
            display: none !important
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector
        height: 38px
    .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder
        line-height: 37px