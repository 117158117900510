.startBox 
    display: flex
    align-items: center
    justify-content: flex-start

.justify-between
    justify-content: space-between  !important

.justify-end
    justify-content: flex-end !important

.d-flex
    display: flex !important